import axios from 'axios';

import { IIndexable } from '@/customtypes/common';

const getBlogFilters: IIndexable = {
  ETH: 'ethereum',
  BNB: 'bnbchain',
  POLYGON: 'polygon',
};

export const getBlogs = async (token: string) => {
  try {
    const response = await axios.get(
      `https://www.staderlabs.com/blog/ghost/api/content/posts/?key=${
        process.env.NEXT_PUBLIC_BLOGS_KEY
      }&limit=3&filter=tag:${
        getBlogFilters[token || '']
      }`,
      {
        withCredentials: false,
      },
    );

    return response as any;
  } catch (error) {
    // sentryErrorLog(error);
    return {
      error,
    };
  }
};
