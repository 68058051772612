import { useCallback, useEffect, useState } from 'react';

import { Box, Button, Flex, Link, Show, Text } from '@chakra-ui/react';

import { BLOGS_VIEW_MORE, ON_BLOG_CLICK } from '@/constants/analytics';
import { StrapiSectionDetail } from '@/customtypes/global';
import { trackEvent } from '@/dapp-sdk-v2';
import BaseImage from '@/dapp-sdk-v2/components/Image';

import Section from '../Section';
import Carousel from './components/Carousel';
import { getBlogs } from './service';
import { usePathname } from 'next/navigation';
import { getCurrentChain } from '@/utils/common';

interface BlogsProps {
  sectionDetails: StrapiSectionDetail;
}

const blogTagMapping: { [x: string]: string } = {
  sd: 'sd',
  hedera: 'hedera',
  polygon: 'polygon',
  fantom: 'fantom',
  'terra-2-0': 'terra-2-0',
  near: 'near',
  ethereum: 'ethereum',
  bnbchain: 'bnbchain',
  'staking-basics': 'staking-basics',
};

const Blogs = ({ sectionDetails }: BlogsProps) => {
  const pathname = usePathname()
  const token = getCurrentChain(pathname);

  const [blogs, setBlogs] = useState<Array<any>>([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      const response = await getBlogs(token);

      setBlogs(response.data?.posts || []);
    };
    fetchBlogs();
  }, []);

  const genBlogLink = useCallback(
    (tags: any, slug: string) => {
      const tag = tags?.[0]?.slug;
      if (tag && blogTagMapping[tag]) {
        return `/blogs/${tag}/${slug}`;
      }
      return `/blogs/${slug}`;
    },
    [blogs],
  );

  return (
    <Section
      title={sectionDetails.title}
      subTitle={sectionDetails.sub_title}
      id={sectionDetails.section_id}
    >
      <Show above="lg">
        <Flex
          mt={16}
          flexDir={{ base: 'column', lg: 'row' }}
          w="100%"
          gap="40px"
          alignItems="center"
          justifyContent="space-between"
        >
          {blogs.map(
            ({
              id,
              title,
              reading_time,
              updated_at,
              feature_image,
              slug,
              tags,
            }) => (
              <Link
                href={genBlogLink(tags, slug)}
                rel="canonical"
                isExternal
                key={id}
                onClick={() =>
                  trackEvent(ON_BLOG_CLICK, {
                    slug: slug,
                  })
                }
              >
                <Box
                  w={{ base: 328, lg: 371 }}
                  h={{ base: 323, lg: 356 }}
                  borderRadius="1rem"
                  overflow="hidden"
                >
                  <Show above="lg">
                    <BaseImage
                      width={371}
                      height={210}
                      src={feature_image}
                      objectFit="cover"
                      alt={title}
                    />
                  </Show>
                  <Show below="lg">
                    <BaseImage
                      width={328}
                      height={169}
                      src={feature_image}
                      objectFit="cover"
                      alt={title}
                    />
                  </Show>
                  <Flex
                    flexDir="column"
                    justifyContent="space-between"
                    textAlign="left"
                    bg="#E6F8EF"
                    p={6}
                    borderRadius={16}
                    borderTopRadius={0}
                    h="165px"
                    pb={10}
                  >
                    <Text
                      fontSize="20px"
                      lineHeight="120%"
                      fontWeight={600}
                      color="#000000"
                    >
                      {title}
                    </Text>
                    <Text
                      fontSize="14px"
                      lineHeight="120%"
                      fontWeight={500}
                      color="textSecondary"
                    >
                      {reading_time} mins read / updated on{' '}
                      {new Date(updated_at).toDateString()}
                    </Text>
                  </Flex>
                </Box>
              </Link>
            ),
          )}
        </Flex>

        {sectionDetails?.cta_text && (
          <Link href={sectionDetails.cta_link} isExternal>
            <Button
              mt={16}
              variant="outline"
              size="xl"
              w="201px"
              h="58px"
              borderColor="seperator"
              _hover={{
                color: 'white',
                bg: 'primary',
              }}
              onClick={() => trackEvent(BLOGS_VIEW_MORE)}
            >
              {sectionDetails.cta_text}
            </Button>
          </Link>
        )}
      </Show>

      <Show below="lg">
        <Carousel blogs={blogs} />
      </Show>
    </Section>
  );
};

export default Blogs;
